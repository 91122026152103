<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">分类：</div>
          <div class="item-content-div">
            <label>
              <select class="type-select" v-model="videoInfo.type">
                <option value="">请选择类型</option>
                <option value="1">基础视频</option>
                <option value="3">属具安装</option>
                <option value="4">视频案例</option>
              </select>
            </label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">品牌：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="teachBrandNameAction" :change="onBrandChanged" placeholder-txt="请选择品牌"/>
            <label v-if="brandName.length > 0">{{`(${brandName})`}}</label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">讲师：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="lecturerNameAction" :change="onLecturerChanged" placeholder-txt="请选择讲师"/>
            <label>{{`(${lecturerName})`}}</label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频名称：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-div" name="videoTitle" type="text" v-model="videoInfo.title" maxlength="60" placeholder="视频名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input name="videoPrice" type="text" v-model="videoInfo.price" maxlength="8" placeholder="请输入价格">元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频简介：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="videoInfo.memo" maxlength="200" placeholder="请输入视频简介"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getLecturerNameItems, getTeachBrandNameItems, getLecturerVideoInfo, postLecturerVideoEdit } from "@/common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    lecturerVideoId: {
      type: String,
      default: null
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      videoInfo: {
        type: "",
        brandId: "",
        lecturerId: "",
        title: "",
        memo: "",
        price: ""
      },
      lecturerNameAction: getLecturerNameItems,
      teachBrandNameAction : getTeachBrandNameItems,
      lecturerName: "",
      brandName: "",
      tipMsg: ""
    }
  },
  created() {
    if(this.lecturerVideoId == null){
      this.$layer.close(this.layerid);
      this.$layer.msg("请选择讲师视频");
      return;
    }

    getLecturerVideoInfo({id: this.lecturerVideoId})
      .then(data => {
        this.videoInfo.type = data.type;
        this.videoInfo.title = data.title;
        this.videoInfo.memo = data.memo;
        this.videoInfo.price = data.price / 100;
        this.lecturerName = data.lecturerName;
        if(Object.prototype.hasOwnProperty.call(data, "brandName")){
          this.brandName = data.brandName;
        }
      })
      .catch(error => {
        this.$layer.close(this.layerid);
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.$layer.msg(error.data.msg);
        } else {
          this.$layer.msg("查询讲师视频信息失败，请稍后重试");
        }
      });
  },
  methods: {
    onBrandChanged(item) {
      this.videoInfo.brandId = item.id
    },
    onLecturerChanged(item) {
      this.videoInfo.lecturerId = item.id
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.videoInfo.type.length < 1){
        this.setTipsMsg("请选择视频类型");
        return;
      }
      if(this.videoInfo.lecturerId <= 0){
        this.setTipsMsg("请选择讲师");
        return;
      }

      let formData = new FormData();
      formData.append("id", this.lecturerVideoId);
      formData.append("lecturerId", this.videoInfo.lecturerId);
      formData.append("brandId", this.videoInfo.brandId);
      formData.append("type", this.videoInfo.type);
      formData.append("title", this.videoInfo.title);
      formData.append("memo", this.videoInfo.memo);
      formData.append("price", `${this.videoInfo.price * 100}`);

      this.$refs.form.validate().then(res => {
        if(res){
          postLecturerVideoEdit(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    onUploadProgress(progressEvent) {
      this.uploadMsg.total = progressEvent.total;
      this.uploadMsg.loaded = progressEvent.loaded;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
        margin-right: 15px;
      }
      .item-content-div {
        display: inline-flex;
        .type-select {
          width: 110px;
          height: 35px;
          padding: 0 3px;
          border: 1px solid #dcdcdc;
          option:disabled {
            text-align: center;
          }
        }
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .lecturer-select {
          width: 230px;
          height: 33px;
          margin-right: 5px;
        }
        .video-name-div {
          width: 218px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>