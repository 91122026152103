<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">分类：</div>
          <div class="item-content-div">
            <label>
              <select class="type-select" v-model="videoInfo.type">
                <option value="">请选择类型</option>
                <option value="1">基础视频</option>
                <option value="3">属具安装</option>
                <option value="4">视频案例</option>
              </select>
            </label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">品牌：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="teachBrandNameAction" :change="onBrandChanged" placeholder-txt="请选择品牌"/>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">讲师：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="lecturerNameAction" :change="onLecturerChanged" placeholder-txt="请选择讲师"/>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频名称：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:50" v-slot="{ errors }">
              <label><input class="video-name-div" name="videoTitle" type="text" v-model="videoInfo.title" maxlength="50" placeholder="视频名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">播放时长：</div>
          <div class="item-content-div">
            <validation-provider rules="required|numeric" v-slot="{ errors }">
              <label><input name="videoTime" type="text" v-model="videoInfo.time" placeholder="请输入播放时长">分钟</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input name="videoPrice" type="text" v-model="videoInfo.price" maxlength="8" placeholder="请输入价格">元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">封面图片：</div>
          <div class="item-content-div">
            <div class="video-img-div" v-show="coverImg != null">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg">
              <img ref="img" src="" alt="" @click="onShowBigImgDiv($event)">
            </div>
            <div v-show="coverImg == null">
              <label class="upload-img-label" @change="onAddImg($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频文件：</div>
          <div>
            <input class="cursor-el" type="file" @change="onAddFile($event)"/>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">视频简介：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }">
              <label><textarea name="introduce" v-model="videoInfo.memo" maxlength="200" placeholder="请输入视频简介"/></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getLecturerNameItems, getTeachBrandNameItems, getCancelTokenSource, postLecturerVideoAdd } from "@/common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      videoInfo: {
        type: "",
        brandId: "",
        lecturerId: "",
        title: "",
        memo: "",
        time: "",
        price: ""
      },
      uploadMsg: {},
      lecturerNameAction: getLecturerNameItems,
      teachBrandNameAction : getTeachBrandNameItems,
      coverImg: null,
      mediaFile: null,
      isShowBigImg: false,
      tipMsg: ""
    }
  },
  methods: {
    onBrandChanged(item) {
      this.videoInfo.brandId = item.id
    },
    onLecturerChanged(item) {
      this.videoInfo.lecturerId = item.id
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.coverImg = img;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onAddFile(event) {
      if(event.target.files.length < 1){
        return;
      }
      this.mediaFile = event.target.files[0];
    },
    onDelImg() {
      this.coverImg = null;
      this.$refs.img.src = "";
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.videoInfo.type.length < 1){
        this.setTipsMsg("请选择视频类型");
        return;
      }
      if(this.videoInfo.lecturerId <= 0){
        this.setTipsMsg("请选择讲师");
        return;
      }
      if(this.mediaFile == null){
        this.setTipsMsg("请上传视频");
        return;
      }

      let formData = new FormData();
      formData.append("lecturerId", this.videoInfo.lecturerId);
      formData.append("type", this.videoInfo.type);
      formData.append("brandId", this.videoInfo.brandId);
      formData.append("title", this.videoInfo.title);
      formData.append("memo", this.videoInfo.memo);
      formData.append("time", this.videoInfo.time);
      formData.append("price", `${this.videoInfo.price * 100}`);
      formData.append("file", this.mediaFile);
      if(this.coverImg != null){
        formData.append("img", this.coverImg);
      }

      this.$refs.form.validate().then(res => {
        if(res){
          this.uploadMsg = {
            source: getCancelTokenSource(),
            name: this.mediaFile.name,
            total: 0,
            loaded: 0,
            state: 3,
          }
          this.$store.state.uploadQueue.push(this.uploadMsg);
          postLecturerVideoAdd(formData, this.uploadMsg.source.token, this.onUploadProgress)
            .then(() => {
              this.uploadMsg.state = 1;
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.uploadMsg.state = error.data.status === -1 ? -1 : 0;
              } else {
                this.uploadMsg.state = 0;
              }
            });

          this.$layer.close(this.layerid);
        }
      });
    },
    onUploadProgress(progressEvent) {
      this.uploadMsg.total = progressEvent.total;
      this.uploadMsg.loaded = progressEvent.loaded;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
        margin-right: 15px;
      }
      .item-content-div {
        display: inline-flex;
        .type-select {
          width: 110px;
          height: 35px;
          padding: 0 3px;
          border: 1px solid #dcdcdc;
          option:disabled {
            text-align: center;
          }
        }
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .lecturer-select {
          width: 230px;
          height: 33px;
        }
        .video-name-div {
          width: 218px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
      .video-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
        }
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>